<template>
  <div class="commission-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Search-->
      <div class="row">
        <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Enter search text"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="col-2 col-lg-3 p-0 mb-3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-10 col-lg-1 offset-lg-5 mb-3">
          <div class="flex-end">
            <b-icon-arrow-counterclockwise
              @click="getCommissions"
              class="btn__refresh"
              v-b-tooltip.hover
              title="Refresh"
            />
          </div>
        </div>
      </div>
      <!--Commissions table-->
      <div v-if="!loading" class="row">
        <b-table
          class="table"
          hover
          striped
          :items="Commissions"
          :fields="fieldsCommissions"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!--ISO Rep column-->
          <template #cell(ISORep)="row">
            <div v-if="row.item.ISORep">
              {{ row.item.ISORep.FirstName ? row.item.ISORep.FirstName : '' }}
              {{ row.item.ISORep.LastName ? row.item.ISORep.LastName : '' }}
            </div>
          </template>
          <!--Account Name Router Link column-->
          <template #cell(Account)="row">
            <div v-if="row.item.Account">
              <router-link
                v-if="row.item.Account.Id && row.item.Account.Name"
                :to="'account/' + row.item.Account.Id"
                v-b-tooltip.hover
                title="Account information"
                class="active-link-table"
              >
                {{ row.item.Account.Name ? row.item.Account.Name : '' }}
              </router-link>
            </div>
          </template>
          <template #cell(Commission)="row">
            <div v-if="row.item.Commission">
              {{ row.item.Commission + '%' }}
            </div>
          </template>
        </b-table>
        <div v-if="!Commissions.length" class="col-12 flex-center">
          <p class="text-center p-5">No records found.</p>
        </div>
        <!--Table Pagination-->
        <div class="col-12 flex-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './CommissionsPage.scss'
import CommissionsService from '@/services/commissions/commissions.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'CommissionsPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Commissions',
    titleTemplate: '%s | ISO Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Commissions',
      Commissions: [],
      fieldsCommissions: [
        {
          key: 'Name',
          label: 'Contract №',
          sortable: true,
        },
        {
          key: 'Account',
          label: 'DBA Name',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'Commission',
          label: 'Commission, %',
          sortable: true,
        },
        {
          key: 'TotalCommission',
          label: 'Total Commission',
          sortable: true,
        },
        {
          key: 'AmountPaid',
          label: 'Amount Paid',
          sortable: true,
        },
        {
          key: 'Clawback',
          label: 'Clawback',
          sortable: true,
        },
        {
          key: 'Balance',
          label: 'Balance',
          sortable: true,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getCommissions()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getCommissions()
    } else this.$system.firstLoading()
  },
  methods: {
    async getCommissions() {
      try {
        this.loading = true
        const response = await CommissionsService.getCommissions(
          this.$system.user,
        )
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.Commissions = response.body.data
          if (response.body.isMain) {
            let field = {
              key: 'ISORep',
              label: 'ISO REP',
              sortable: false,
            }
            this.fieldsCommissions.push(field)
          }
          this.totalRows = this.Commissions.length
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
  },
}
</script>
