import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5001/api/';

class CommissionsService {
  async getCommissions(user) {
    const { data } = await Axios.post(`${API_URL}commissions`, user);
    return data;
  }
}

export default new CommissionsService();