<template>
  <div class="row">
    <div class="title mt-3 mb-3">
      <h2>{{ title }}</h2>
    </div>
  </div>
</template>

<script>
import './Title.scss'

export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
